import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createGtm } from '@gtm-support/vue-gtm'

import stores from './stores'
import { router } from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import App from './App.vue'
import { getMonthRange, getQuarterRange, getYearRange } from './utils/date'
import ClickOutsideDirective from './directives/ClickOutsideDirective'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

app.component('VueDatePicker', VueDatePicker);
// Added custom directives to the app
app.directive('click-outside', ClickOutsideDirective);

app.use(stores)
app.use(router)
app.use(i18n)
app.use(createVuestic({ 
  config: {
    ...vuesticGlobalConfig,
    components: {
      VaDateInput: {
        mode: 'range',
        rangeDelimiter: ', ',
        formatDate: (date: Date) => date.toLocaleDateString('en-US'),
        parseDate: (dateString: string) => {
          const [day, month, year] = dateString.split('/')
          return new Date(Number(year), Number(month) - 1, Number(day))
        }
      },
    }
  } 
}))

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')
