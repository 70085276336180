<template>
  <div class="profile-dropdown-wrapper">
    <!-- <VaDropdown :offset="[9, 0]" class="profile-dropdown" stick-to-edges>
      <template #anchor>
        <VaButton preset="secondary" color="textPrimary">
          <span class="profile-dropdown__anchor min-w-max">
            <slot />
            <div class="flex gap-2 items-center capitalize">
              <VaAvatar gradient color="primary" size="small">{{ profileIconName() }}</VaAvatar>
              <span>{{ store.GetAuthState().user?.name }}</span>
            </div>
          </span>
        </VaButton>
      </template>
      <VaDropdownContent
        class="profile-dropdown__content md:w-60 px-0 py-4 w-full"
        :style="{ '--hover-color': hoverColor }"
      >
        <VaList v-for="group in options" :key="group.name">
          <header v-if="group.name" class="uppercase text-[var(--va-secondary)] opacity-80 font-bold text-xs px-4">
            {{ t(`user.${group.name}`) }}
          </header>
          <VaListItem
            v-for="item in group.list"
            :key="item.name"
            class="menu-item px-4 text-base cursor-pointer h-8"
            v-bind="resolveLinkAttribute(item)"
          >
            <VaIcon :name="item.icon" class="pr-1" color="secondary" />
            {{ t(`user.${item.name}`) }}
          </VaListItem>
          <VaListSeparator v-if="group.separator" class="mx-3 my-2" />
        </VaList>
      </VaDropdownContent>
    </VaDropdown> -->
    <div class="relative hover:bg-slate-100 p-2 px-4 rounded-lg mr-2">
      <button @click="toggleDropdown" v-click-outside="closeDropdown" class="profile-dropdown">
        <span class="profile-dropdown__anchor min-w-max">
            <div class="flex gap-2 items-center capitalize">
              <VaAvatar gradient color="primary" size="small">{{ profileIconName() }}</VaAvatar>
              <span class="font-semibold">{{ store.GetAuthState().user?.name }}</span>
            </div>
          </span>
      </button>
      <div :class="{ hidden: !isShown }" class="absolute top-12 right-0 bg-white shadow-md">
        <VaDropdownContent
          class="profile-dropdown__content md:w-60 px-0 py-4 w-full"
        >
          <VaList v-for="group in options" :key="group.name">
            <header v-if="group.name" class="uppercase text-[var(--va-secondary)] opacity-80 font-bold text-xs px-4">
              {{ t(`user.${group.name}`) }}
            </header>
            <VaListItem
              v-for="item in group.list"
              :key="item.name"
              class="menu-item px-4 text-base cursor-pointer h-8"
              v-bind="resolveLinkAttribute(item)"
            >
              <VaIcon :name="item.icon" class="pr-1" color="secondary" />
              {{ t(`user.${item.name}`) }}
            </VaListItem>
            <VaListSeparator v-if="group.separator" class="mx-3 my-2" />
          </VaList>
        </VaDropdownContent>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'
import { useAuthStore } from '../../../../stores/auth-store'

const store = useAuthStore()
const { role_name, name } = store.GetAuthState().user;

const { colors, setHSLAColor } = useColors()
const hoverColor = computed(() => setHSLAColor(colors.focus, { a: 0.1 }))

const { t } = useI18n()

type ProfileListItem = {
  name: string
  to?: string
  href?: string
  icon: string
}

type ProfileOptions = {
  name: string
  separator: boolean
  list: ProfileListItem[]
}

const options = [
  {
    name: 'account',
    separator: true,
    list: [
      {
        name: 'preferences',
        to: role_name == 'admin' || role_name == 'staff' ? 'preferences' : 'vendors-preferences',
        icon: 'mso-account_circle',
      },
    ],
  },
  {
    name: '',
    separator: false,
    list: [
      {
        name: 'logout',
        to: 'login',
        icon: 'mso-logout',
      },
    ],
  },
]

const isShown = ref(false)

const toggleDropdown = () => {
  isShown.value = !isShown.value
}

const closeDropdown = () => {
  isShown.value = false
}

const resolveLinkAttribute = (item: ProfileListItem) => {
  if (item.name == 'logout') {
    return {
      // Handle logout on click
      // { onClick: logout(), target: '_blank' }
      href: '#',
      onClick: () => logout(),
    }
  }
  return item.to ? { to: { name: item.to } } : item.href ? { href: item.href, target: '_blank' } : {}
}
const logout = () => {
  const authStore = useAuthStore()
  return authStore.logout()
}

const profileIconName = (): string => {
  const authStore = useAuthStore()

  const fullName = authStore.user?.name || ''
  const nameParts = fullName.split(' ')

  const firstNameInitial = nameParts[0]?.charAt(0).toUpperCase() || ''
  const surnameInitial = nameParts[1]?.charAt(0).toUpperCase() || ''

  const initials = `${firstNameInitial} ${surnameInitial}`
  return initials
}
</script>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  &__content {
    .menu-item:hover {
      background: var(--hover-color);
    }
  }

  &__anchor {
    display: inline-block;
  }
}
</style>
