import { Job, SyncProgress } from '../components/navbar/type'
import { httpClient } from '../helpers/axios-wrapper'
import { DashboardMetric } from '../pages/admin/dashboard/types'

export const dashboardService = {
  getMetrics,
  getBillMetrics,
  getMasterSyncProgress,
  startMasterSync,
}

async function getMetrics(role_name: string): Promise<DashboardMetric[]> {
  const prefix = role_name == "admin" || role_name === "staff" ? "" : `/vendor`;
  return await httpClient.get<DashboardMetric[]>(`${prefix}/metrics`)
}

async function getBillMetrics(role_name: string): Promise<DashboardMetric[]> {
  const prefix = role_name == "admin" || role_name === "staff" ? "" : `/vendor`;
  return await httpClient.get<DashboardMetric[]>(`${prefix}/billmetrics`);
}

async function getMasterSyncProgress(role_name: string): Promise<SyncProgress> {
  const prefix = role_name == "admin" || role_name === "staff" ? "" : `/vendor`;
  return await httpClient.get<SyncProgress>(`${prefix}/master-sync-progress`)
}

async function startMasterSync(role_name: string): Promise<Job> {
  const prefix = role_name == "admin" || role_name === "staff" ? "" : `/vendor`;
  return await httpClient.post<Job>(`${prefix}/start-master-sync`, {})
}
