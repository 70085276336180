export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/vendors',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'vendors-bills',
      displayName: 'menu.bills',
      meta: {
        icon: 'receipt_long',
      },
    },
    {
      name: 'vendors-payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'payments',
      },
    },
    {
      name: 'vendors-preferences',
      displayName: 'menu.preferences',
      meta: {
        icon: 'manage_accounts',
      },
    },
    // {
    //   name: 'access-management',
    //   displayName: 'menu.accessmanagement',
    //   meta: {
    //     icon: 'widgets',
    //   },
    //   children: [
    //     {
    //       name: 'app-members',
    //       displayName: 'menu.appusers',
    //       meta: {
    //         icon: 'manage_accounts',
    //       },
    //     }
    //   ],
    // },
  ] as INavigationRoute[],
}
